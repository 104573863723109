/* Reset CSS */

html,
body {
    background: #fff;
    z-index: 1;
    font-size: 16px;
    font-size: 100%;
    padding: 0;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    height: var(--app-height);
}

a,
button,
input,
label {
    cursor: pointer;
}

*,
*:after,
*:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* CSS TO COPY */

@font-face {
    font-family: "TimesNewArial";
    src: url(TimesNewArialGX.7e4f6dda.ttf);
}

body {
    font-family: "TimesNewArial";
    font-variation-settings: "srff" 0, "ital" 0;
    background-color: #646464;
    font-size: 1.75vw;
}

body.cursor-wait:not(.active) {
        cursor: wait !important;
    }

* {
    font-weight: normal;
    font-style: normal;
    color: #b1b1b2;
}

.overflow-hidden {
    overflow: hidden;
    position: relative;
    height: var(--app-height, "100vh");
    position: relative;
}

.playtime-marker {
    color: Chartreuse;
}

.playtime-marker.current::before {
            content: var(--video-playtime-current, "00:00");
        }

.playtime-marker.total::before {
            content: var(--video-playtime-total, "00:00");
        }

body.desktop .device-indicator[device-target="desktop"],
body.mobile-portrait .device-indicator[device-target="mobile-portrait"],
body.mobile-portrait .device-indicator[device-target="mobile"],
body.mobile-landscape .device-indicator[device-target="mobile"],
body.mobile-landscape .device-indicator[device-target="mobile-landscape"] {
    --indicator-color: Chartreuse;
}

.device-indicator {
    --indicator-color: Red;
    height: 0.9em;
    width: 0.9em;
    display: inline-block;
    vertical-align: middle;
    background-color: var(--indicator-color);
    border-radius: 50%;
    filter: blur(0.2em);
}

.text {
}

main.text {
    font-size: 0;
    padding: 1vw;
    display: block;
    width: 100vw;
    height: var(--app-height);
    overflow: scroll;
    overflow-wrap: break-word;
}

main.text[hidden] {
        display: none;
    }

main.text img {
        height: 3vw;
        margin-bottom: 1vw;
        margin-right: 2vw;
        display: inline-block;
    }

main.text div {
        width: 48.5vw;
        vertical-align: top;
        display: inline-block;
    }

main.text div:first-of-type {
            margin-right: 1vw;
        }

main.text h1 {
        font-size: 2.25vw;
        line-height: 2.25vw;
        font-variation-settings: "srff" 100, "ital" 0;
    }

main.text h1:nth-of-type(2) {
            margin-top: 5vw;
        }

main.text h1 em {
            font-variation-settings: "srff" 100, "ital" 100;
        }

main.text h1 {
        margin-bottom: 1vw;
}

main.text h1 span {
            margin-left: 0.5vw;
            vertical-align: top;
            font-size: 1.25vw;
            line-height: 1.5vw;
            font-variation-settings: "srff" 0, "ital" 0;
        }

main.text p {
        font-size: 1.25vw;
        line-height: 1.5vw;
        font-variation-settings: "srff" 0, "ital" 0;
        margin-bottom: 1vw;
    }

main.text p em {
            font-variation-settings: "srff" 0, "ital" 100;
        }

main.text p .time span {
                color: Chartreuse;
            }

main.text p .activestate {
            height: 1.15vw;
            width: 1.15vw;
            display: inline-block;
            vertical-align: middle;
            background-color: red;
            border-radius: 1vw;
            filter: blur(2px);
        }

main.text p .activestate.active {
                background-color: Chartreuse;
            }

@media screen and (max-width: 900px) {
        main.text div {
            width: 98vw;
            display: block;
        }
            main.text div:first-of-type {
                margin-right: 0vw;
            }
            main.text div {
            margin-bottom: 10vw;
    }
        main.text img {
            height: 10vw;
            margin-bottom: 4vw;
            margin-right: 6vw;
            display: inline-block;
        }
        main.text h1 {
            font-size: 8vw;
            line-height: 8vw;
        }
            main.text h1:nth-of-type(2) {
                margin-top: 10vw;
            }
            main.text h1 {
            margin-bottom: 2vw;
    }
            main.text h1 span {
                margin-left: 0.5vw;
                font-size: 5vw;
                line-height: 5vw;
            }
        main.text p {
            font-size: 5vw;
            line-height: 6vw;
            margin-bottom: 2vw;
        }
            main.text p .activestate {
                height: 5vw;
                width: 5vw;
                vertical-align: middle;
                border-radius: 5vw;
                filter: blur(2px);
            }
}

/* ------------ END TEXT */

.icon {
    cursor: pointer;
    width: 2vw;
    height: 2vw;
    border-radius: 10vw;
    background-color: #646464;
    box-shadow: 0px 0px 15px 8px rgba(177, 177, 178, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0.5vw;
    transition: width 1s ease, height 1s ease;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.icon .activestate {
    display: none;
}

.icon.active .passivestate {
    display: none;
}

.icon.active .activestate {
    display: flex;
}

.icon#mute.active img {
    margin-right: 7.5%;
    width: 40%;
}

.icon#mute img {
    margin-right: 0%;
    margin-left: 1.5%;
    width: 75%;
}

.icon#settings img {
    width: 55%;
}

.icon#settings.active img {
    width: 55%;
    margin-bottom: -5%;
}

.icon#how img {
    width: 45%;
    margin-bottom: -5%;
}

.icon#how.active img {
    width: 55%;
    margin-bottom: -5%;
}

.icon#about img {
    width: 60%;
    margin-bottom: -5%;
}

.icon#about.active img {
    width: 55%;
    margin-bottom: -5%;
}

.controls {
    opacity: 1;
    transition: opacity 0.5s;
}

.controls[hidden] {
        opacity: 0;
    }

.controls {
    position: absolute;
    bottom: 1vmax;
    width: calc(100% - 2vmax);
    max-width: calc(100vw - 2vmax);
    left: 0;
    right: 0;
    margin: auto;
    height: auto;
    display: flex;
    justify-content: space-between;
    z-index: 999;
}

.box {
    z-index: 400;
}

.box,
.desktop_device {
    pointer-events: none;
    transition: left 1s ease, right 1s ease, top 1s ease, bottom 1s ease;
}

.box .icon,
.desktop_device .icon {
    pointer-events: auto;
}

.image {
    position: absolute;
}

.video {
    position: absolute;
    box-shadow: 0px 0px 15px 8px rgba(177, 177, 178, 0.75);
}

.video video {
    mix-blend-mode: unset;
    opacity: 1;
    transition: opacity 1s ease;
    background-color: #636363;
}

.video video.transparent {
    opacity: 0.05;
}

.portrait_device.mobile .video,
.landscape_device.mobile .video {
    z-index: 2;
    border-radius: 1.5vmax;
}

.name {
    display: none;
}

.indicator {
    width: 1.5vw;
    height: 1.5vw;
    background: red;
    border-radius: 2vw;
    display: inline-block;
    margin-bottom: -0.15vw;
    margin-right: -0.15vw;
    filter: blur(3px);
}

.indicator.active {
    background: #00ff00;
}

/* ---------------------- PORTRAIT DEVICE */

.move-animation {
    transition: transform var(--animation-speed, 1s) ease,
        width var(--animation-speed, 1s) ease,
        height var(--animation-speed, 1s) ease;
    transform: translate(0vmax, 0vmax);
    will-change: transform, width, height;
}

.portrait_device {
    position: absolute;
    width: 30vmax;
    height: 50vmax;
    bottom: 0;
    left: 0;
}

@media screen and (max-height: 50vmax) {
    .portrait_device {
        top: 0;
    }
}

.portrait_device .image {
    transition: all 1s ease, opacity 1.5s ease;
    width: 46vmax;
    left: -22.15vmax;
    z-index: 9;
    top: 10.91vmax;
}

.portrait_device .video {
    transition: all 1s ease;
    left: 5vmax;
    width: 15.55vmax;
    top: 5vmax;
    height: 32.5vmax;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portrait_device .video video {
    height: 32.5vmax;
}

.portrait_device .name {
    z-index: 99;
    line-height: 1vmax;
    bottom: 10.5vmax;
    left: 5vmax;
    position: absolute;
}

body.active:not(.mobile-portrait) .portrait_device {
            transform: translate(-30vmax, 51vmax);
            /* left: -30vmax; */
            /* bottom: -51vmax; */
        }

body.active.mobile-portrait .portrait_device {
            width: 100vmin;
            height: 100vmax;
            bottom: 0vmax;
        }

body.active.mobile-portrait .portrait_device .icon {
            width: 12vmin;
            height: 12vmin;
        }

body.active.mobile-portrait .portrait_device .image {
            transition: all 1s ease, opacity 1.5s ease;
            width: 299vmin;
            left: -177vmin;
            z-index: 9;
            top: 6vmin;
            filter: blur(5px);
            opacity: 0;
        }

body.active.mobile-portrait .portrait_device .image img {
            width: 100%;
        }

body.active.mobile-portrait .portrait_device .video {
            left: 0vmax;
            width: 100vmin;
            top: 0vmax;
            height: 100vmax;
            border-radius: 0;
        }

body.active.mobile-portrait .portrait_device .video video {
            min-width: 100vmin;
            min-height: 100vmax;
            height: auto;
            width: auto;
        }

/* ---------------------- LANDSCAPE DEVICE */

.landscape_device {
    position: absolute;
    right: 0;
    width: 60vmax;
    height: 30vmax;
    transform: translate(0, -4vmax);
    overflow: hidden;
}

.landscape_device .image {
    transition: all 1s ease, opacity 1.5s ease;
    height: 28.45vmax;
    right: -20.7vmax;
    top: 0;
    z-index: 9;
    filter: blur(0px);
}

.landscape_device .video {
    transition: all 1s ease;
    left: 23vmax;
    width: 32.5vmax;
    top: 9.865vmax;
    height: 15.55vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.landscape_device .video video {
    width: 32.5vmax;
}

.landscape_device .name {
    z-index: 99;
    line-height: 1vmax;
    bottom: 2.75vmax;
    left: 23vmax;
    position: absolute;
}

body.active:not(.mobile-landscape) .landscape_device {
            transform: translate(60vmax, 0);
        }

body.active.mobile-landscape .landscape_device {
            width: 100%;
            height: 100%;
            transform: translate(0, 0);
        }

body.active.mobile-landscape .landscape_device .image {
            height: 102.5vmax;
            right: -90.75vmax;
            top: -35.5vmax;
            z-index: 9;
            filter: blur(5px);
            opacity: 0;
        }

body.active.mobile-landscape .landscape_device .icon {
            width: 7vw;
            height: 7vw;
        }

body.active.mobile-landscape .landscape_device .video {
            left: 0vmax;
            top: 0vmax;
            width: 100vmax;
            height: var(--app-height, "100vh");
            border-radius: 0;
        }

body.active.mobile-landscape .landscape_device .video video {
            min-width: 100%;
            min-height: 100%;
            height: auto;
            width: auto;
        }

/* ---------------------- DESKTOP DEVICE */

.desktop_device {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 75vw;
    height: 42vw;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0px 0px 15px 8px rgba(177, 177, 178, 0.75); */
}

.desktop_device .video {
    height: 100%;
    overflow: visible;
    display: flex;
    justify-content: space-between;
}

.desktop_device .controls {
    bottom: 0.5vw;
}

.desktop_device .icon {
    margin-bottom: 0.25vw;
}

.desktop_device video {
    min-width: 75vw;
    height: 42.25vw;
    transition: mix-blend-mode 1s ease, opacity 1s ease;
    will-change: opacity;
    -o-object-fit: cover;
       object-fit: cover;
}

.desktop_device .name {
    z-index: 99;
    line-height: 1vw;
    bottom: -2vw;
    left: 0vw;
    position: absolute;
}

body.active:not(.desktop) .desktop_device {
            transform: translate(-150vmax, -150vmax);
        }

body.active.desktop .desktop_device {
            width: 100%;
            height: 100%;
        }

body.active.desktop .desktop_device .video {
            width: 100%;
            height: 100%;
        }

body.active.desktop .desktop_device .video video {
            width: 100%;
            height: 100%;
        }

/*# sourceMappingURL=index.4ebec5c1.css.map */
